import type { StateDeclarationFn } from 'controller/types';
import type { IApplicationUser } from 'store/application';

export default (function (vm) {
  return {
    name: 'members.groups.my-posts',
    url: '/my-posts',
    resolve: [
      {
        token: 'user',
        policy: { async: 'WAIT' },
        async resolveFn() {
          return vm.application$.fetchUserProfile().unwrap();
        },
      },
      {
        token: 'feed',
        deps: ['user'],
        resolveFn(user: IApplicationUser) {
          if (user.memberId) {
            return vm.centralFeed$.fetchByAuthor(user.memberId);
          }
        },
      },
    ],
  };
} as StateDeclarationFn);
